import React from 'react';
import './Footer.css'
export default function Footer() {
    return <div className="footer">
        <div className="footer-container">
            <div>
                <b>Email Us</b>
            </div>
            <div>
                <a className="footer-email link-decoration__off" href="mailto: vteam@veratiles.com">vteam@veratiles.com</a>
            </div>
        </div>
    </div>;
}