import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";

import NavBar from "./navbar/NavBar";
import AboutPage from "./pages/about/AboutPage";
import SavPage from "./pages/sav/SavPage";
import Footer from './footer/Footer'

function App() {
    return (
        <Router>
            <NavBar />
            <main>
                <Route exact path="/" component={Redirect} />
                <Route path="/sav" component={SavPage} />
                <Route path="/about" component={AboutPage} />
                {/* <Route path="/contact" component={ContactPage}/> */}
            </main>
            <Footer />
        </Router>
    );
}

function Redirect(props) {
    props.history.push("/sav");
    return <div />;
}

export default App;
