import React from "react";
import "./About.css";
import VeratilesLogo from "../../../src/svg/vera-glass-tiles.svg";
import Jossell from "../../../src/png/jossell.jpg";
import Kevin from "../../../src/png/kevin.jpg";
import Omar from "../../../src/png/omar.jpg";

export default function AboutPage() {
  return (
    <div className="about-page">
      <div className="bio">
        <div>
          <img className="vera-sun" alt="vera-sun-logo" src={VeratilesLogo} />
        </div>
        <div>
          <h2>Veratiles LLC</h2>
        </div>
        <div className="bio-text">
          <p>
            Veratiles is equiped with versatile engineers who create products
            with no compromise to quality. We are concerned with implementing
            effective designs geared towards helping people acquire economical
            and helpful products.
          </p>
        </div>
      </div>
      <div className="co-founder-bio">
        <div className="bio">
          <div>
            {" "}
            <img className="portrait" alt="kevin-icon" src={Kevin} />
          </div>
          <div>
            <h4>Kevin Rivera</h4>
          </div>
          <div className="bio-text">
            <p>
              Kevin Rivera, co-founder of Veratiles and Chemical Engineering PhD
              student, is an avid tinkerer and learner. As the chief technology
              officer, Kevin develops products featured on veratiles.com. He
              uses his engineering background to rigorously evaluate, create and
              innovate new solutions to modern day problems.
            </p>
          </div>
        </div>
        <div className="bio">
          <div>
            {" "}
            <img className="portrait" alt="jossell-icon" src={Jossell} />
          </div>
          <div>
            <h4>Jossell Berrios</h4>
          </div>
          <div className="bio-text">
            <p>
              Jossell is chief executive officer and co-founder of
              veratiles.com. He is an experienced software engineer enabling solutions for Veratiles. Jossell operates and
              develops veratiles.com along with it's related infrastructure in
              the cloud.{" "}
            </p>
          </div>
        </div>
        <div className="bio">
          <div>
            {" "}
            <img className="portrait" alt="jossell-icon" src={Omar} />
          </div>
          <div>
            <h4>Omar Perez</h4>
          </div>
          <div className="bio-text">
            <p>
              Omar is an Electrical Engineer, co-founder of veratiles and
              Lead Engineer of SuperFlyMicros. He has in-depth experience designing
              and testing electrical systems and has wide breadth of technical
              knowledge in systems engineering and solutions.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
