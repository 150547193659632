import React from 'react';
import {  NavLink } from "react-router-dom";
import './NavBar.css'
import Logo from "../logo/Logo";

export default function NavBar() {
    return (
        <div className="nav">
            <nav className="nav__list">
                <ul>
                    <li className="">
                        <NavLink className="nav-bar__item-link" to="/sav"
                            activeClassName="nav-link--active">
                            <div className="nav-logo">
                                <Logo />

                            </div>
                        </NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink className="nav-bar__item-link" to="/sav"
                            activeClassName="nav-link--active">SAV</NavLink>
                    </li>
                    <li className="nav__item">
                        <NavLink className="nav-bar__item-link" to="/about"
                            activeClassName="nav-link--active">About</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}