import React from "react";
import "./SavePage.css"
import SavFront from '../../png/SAV_front.png';
import SavBottomDiag from '../../png/SAV_bottom_diagonal.png';
import SavInterior from '../../png/SAV_interior_2.png';
import SavTop from '../../png/SAV_top_diagonal.png';

export default function SavPage() {
    return <div className="sav-page-container">
        <section className="save-page">
            <h1 className="sav-title">Small Area Vacuum</h1>
            <section className="sav-promo">
                    <div className="sav sav-front-container">
                        <p className="sav-front-desc">An <b>eco friendly</b> solution to daily mess.</p>
                        <img className="sav-front" alt="sav-front" src={SavFront}/>
                    </div>
                    <div className="sav sav-body-container">
                        <img className="sav-top" alt="sav-body" src={SavTop}/>
                        <p className="sav-body-desc">Body made from premium PLA plastics derived from corn and
                            sugarcane.</p>
                    </div>
                    <div className="sav sav-interior-container">
                        <img className="sav-interior" alt="sav-interior" src={SavInterior}/>
                        <p className="sav-interior-desc">Carefully engineered <b>compacted</b> components.</p>
                    </div>
                    <div className="sav sav-bottom-container">
                        <img alt="sav-bottom" src={SavBottomDiag}/>
                        <p className="sav-bottom-desc">Allowing for fluid movement in minimal space.</p>
                    </div>
            </section>
        </section>
    </div>
}